import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  Card,
  ListGroup,
} from "react-bootstrap";
import { Container } from "react-bootstrap";
import Loader from "../Loader";
import Message from "../Message";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { listServicesDetails } from "../../actions/servicesAction";

function ServicesScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const serviceDetails = useSelector((state) => state.serviceDetails);
  const { loading, error, service } = serviceDetails;
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(listServicesDetails(id));
  }, [dispatch, id]);

  return (
    <div className="container">
      <Link to="/" className="btn btn-dark my-3">
      <i className="fa-solid fa-backward"></i>   Go Back
      </Link>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error} </Message>
      ) : (
        <Row>
          <Col md={3}>
            <Image src={service.image} alt={service.service_name} fluid />
          </Col>

          <Col md={6}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h3>{service.service_name}</h3>
              </ListGroup.Item>
              <ListGroup.Item></ListGroup.Item>

              <ListGroup.Item
                as="p"
                dangerouslySetInnerHTML={{ __html: service.description }}
              ></ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={3}>
            <Card>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col>Experience :</Col>
                    <Col>
                      <strong> {service.experience} Years</strong>
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col>Work Culture:</Col>
                    <Col className="text-success">100 % Authentic</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Years of Relationship:</Col>
                    <Col className="text-primary">{service.clients}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Need Service ? :</Col>
                    <Col className="text-primary">
                    <Link to="/contact" className="btn btn-outline-info">
        Contact
      </Link>
                      
                    </Col>
                  </Row>
                </ListGroup.Item>
               
              </ListGroup>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default ServicesScreen;
