import React from "react";
import { Card } from "react-bootstrap";
import Iframe from 'react-iframe'

function YoutubePlaylistVideos({youtubedata}) {
  return (
<>


    <Card className="my-3 p-3 rounded">
 <Iframe url={`https://www.youtube.com/embed/${youtubedata.videoLink}`}
         position="relative"
         width="100%"
         id="myId"
         className="myClassname"
         height="100%"
         styles={{height: "175px"}}
         />
    <Card.Text as="div">
    </Card.Text>

    <Card.Body>
    
        <Card.Title className="text-center">
          <p>{youtubedata.videoName}</p> 
        </Card.Title>

        <Card.Title className="text-center">
          <p>{youtubedata.videoDescription}</p>
        </Card.Title>
        <Card.Title className="text-center">
        <a className="text-danger text-center" target="_blank" href={`https://youtu.be/${youtubedata.videoLink}` }

        >View from <i className="fa-brands fa-youtube"></i></a>
        </Card.Title>
        
       
   
    </Card.Body>

  </Card>

  </>
  )


}

export default YoutubePlaylistVideos