import React from 'react'
import { Card,Button } from "react-bootstrap";
import {Link} from "react-router-dom"

function Blogs({blg}) {
    const content=blg.content;
   
  return (
    <Card className="my-3 p-3 rounded">


    <Card.Body>
      <Link to={`/blog/${blg.slug}`}>
        <Card.Title as="h4" >
          <strong>{blg.title}</strong>
        </Card.Title>
      </Link>

      <Card.Text as="div"  dangerouslySetInnerHTML={{__html: content.slice(0,200) +'...'}}>

      </Card.Text>  
      <Card.Text as="h5"> 
            Posted on : {blg.timeStamp.slice(0,10)} by {blg.author}
     </Card.Text> 
      <Link to={`/blog/${blg.slug}`}>
      <Button className="btn btn-dark"> Read More <i className="fa-solid fa-circle-info"></i></Button>

    </Link>

    </Card.Body>
  </Card>
  )
}

export default Blogs