import React from "react";
import { Card } from "react-bootstrap/";
import Badge from 'react-bootstrap/Badge';
import { Link } from "react-router-dom";
import Rating from "./Rating";
function Techs({ tech }) {
 
  return (
    <Card  className="my-3 p-3 rounded" >
      <Link to={`/technology/${tech._id}`}>
        <Card.Img  variant="top" style={{ width: 100 }} src={tech.image} />
      </Link>

      <Card.Body>
        <Link to={`/technology/${tech._id}`}>
          <Card.Title as="div">
            <h4>{tech.technology_name}</h4>
          </Card.Title>
        </Link>
        <Card.Text as="div">
          <div className="my-3">
            <Rating value={tech.skillrating} color={"#f8e825"} />
            <Card.Text as="h6">Skill rating {tech.skillrating}</Card.Text>
          </div>
        </Card.Text>
        <Card.Text as="div">
          <div className="my-3">
            <h5>{tech.category}</h5>
          </div>          
        </Card.Text>

        <Card.Text as="p">
          <div className="my-3">
            Experience : <Badge pill bg="dark">
            {tech.experience} Years
      </Badge>  
          </div>          
        </Card.Text>

        <Link className="text-success text-center"  to={`/technology/${tech._id}` }

        >Know More...</Link>
      </Card.Body>
    </Card>
  );
}

export default Techs;
