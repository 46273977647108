import {
    YOUTUBE_LIST_REQUEST,YOUTUBE_LIST_SUCCESS,YOUTUBE_LIST_FAIL,
    YOUTUBE_DETAILS_REQUEST,YOUTUBE_DETAILS_SUCCESS,YOUTUBE_DETAILS_FAIL


} from '../constants/youtubeConstants'


export const youtubeListReducers = (state={ youtube:[] },action) =>{

    switch(action.type){
        case YOUTUBE_LIST_REQUEST:
            return {loading:true,youtube:[]}
        case YOUTUBE_LIST_SUCCESS:
            return {loading:false,youtube:action.payload}
        case YOUTUBE_LIST_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}


export const youtubeDetailsReducers = (state={ youtubes:[] },action) =>{

    switch(action.type){
      
        case YOUTUBE_DETAILS_REQUEST:
            return {loading:true,...state}
        case YOUTUBE_DETAILS_SUCCESS:
            return {loading:false,youtubes:action.payload}
        case YOUTUBE_DETAILS_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}