import {
    BLOGS_LIST_REQUEST,BLOGS_LIST_SUCCESS,
BLOGS_LIST_FAIL,
    BLOGS_DETAILS_REQUEST,BLOGS_DETAILS_SUCCESS,
BLOGS_DETAILS_FAIL,

} from '../constants/blogConstants'
import axios from "axios";

export const listBlogs =() => async (dispatch)=>{

    try{
        dispatch({type:BLOGS_LIST_REQUEST})
        const {data} = await axios.get('/api/blogs/')

        dispatch({
            type:BLOGS_LIST_SUCCESS,
            payload:data
        })

    }
    catch(error){
        dispatch({
            type:BLOGS_LIST_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            :error.message,
        })

    }
}

export const listblogDetails =(slug) => async (dispatch)=>{

    try{
        dispatch({type:BLOGS_DETAILS_REQUEST})
        const {data} = await axios.get(`/api/blog/${slug}`)

        dispatch({
            type:BLOGS_DETAILS_SUCCESS,
            payload:data
        })

    }
    catch(error){
        dispatch({
            type:BLOGS_DETAILS_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            :error.message,
        })

    }
}