import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Container} from "react-bootstrap";

function Workshop({ workshop }) {
  return (
    <>
      <Container>
        <div className="row">
          <div className="col-md-4">
            <Link to={`/workshop/${workshop._id}`}>
              <Card.Img src={workshop.image} />
            </Link>
          </div>
          <div className="col-md-8">
            <Card.Body>
              <Link to={`/workshop/${workshop._id}`}>
                <Card.Title>
                 <h2 className="text-success"> {workshop.workshop_name}</h2>
                </Card.Title>
              </Link>
              <hr />
              <Card.Title as="p" dangerouslySetInnerHTML={{__html: workshop.description}}>
                  
                </Card.Title>
              <Link to={`/workshop/${workshop._id}`}>
                <Button className="btn btn-sm btn-success m-2">
                  {" "}
                  Know More <i className="fa-solid fa-circle-info"></i>
                </Button>
              </Link>
              <Link to={`/enroll/`}>
                <Button className="btn btn-sm btn-success m-2">
                  {" "}
                  Register <i className="fa-solid fa-circle-info"></i>
                </Button>
              </Link>
            </Card.Body>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Workshop;
