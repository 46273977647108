import axios from 'axios'
import {
    YOUTUBE_LIST_REQUEST,YOUTUBE_LIST_SUCCESS,YOUTUBE_LIST_FAIL,
    YOUTUBE_DETAILS_REQUEST,YOUTUBE_DETAILS_SUCCESS,YOUTUBE_DETAILS_FAIL


} from '../constants/youtubeConstants'


export const listYoutube =() => async (dispatch)=>{

    try{
        dispatch({type:YOUTUBE_LIST_REQUEST})
        const {data} = await axios.get('/api/youtube/')

        dispatch({
            type:YOUTUBE_LIST_SUCCESS,
            payload:data
        })

    }
    catch(error){
        dispatch({
            type:YOUTUBE_LIST_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            :error.message,
        })

    }
}


export const listYoutubeDetails =(id) => async (dispatch)=>{

    try{
        console.log(id);
        dispatch({type:YOUTUBE_DETAILS_REQUEST})
        const {data} = await axios.get(`/api/youtubes/${id}`)

        dispatch({
            type:YOUTUBE_DETAILS_SUCCESS,
            payload:data
        })

    }
    catch(error){
        dispatch({
            type:YOUTUBE_DETAILS_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            :error.message,
        })

    }
}