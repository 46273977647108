import React from "react";
import { Card } from "react-bootstrap";

function Works({workdata}) {
  return (
    <Card className="my-3 p-3 rounded">
    <Card.Text as="div">
      <Card.Img src={workdata.companyLogo} style={{ height: 'auto', width:'50%',margin:'auto',display:'block' }} fuild/>
    </Card.Text>

    <Card.Body>
    
        <Card.Title >
          <h4>{workdata.companyName}</h4>
        </Card.Title>
   

      <Card.Text as="div">
          <div className="my-3">
             Experience : {workdata.experience} Years
          </div>
      </Card.Text>

      <Card.Text as="div">
          <div className="my-3 text-black">
             Role : {workdata.role} 
          </div>
      </Card.Text>
<hr />
      <Card.Text as="div">
        <h5>Work Description</h5>
          <div className="my-3 text-black">
          {workdata.workDescription} 
          </div>
      </Card.Text>
      <hr />
      <Card.Text as="div">
          <div className="my-3 text-black">
            <u> Start date</u> :{workdata.startdate} 
          </div>
      </Card.Text>
      
      <Card.Text as="div">
          <div className="my-3 text-black">
           <u> End date</u> : {workdata.enddate} 
          </div>
      </Card.Text>
      

      
    
  
     

      
    </Card.Body>
  </Card>
  )
}

export default Works