import axios from "axios";
import {
  PROJECTS_LIST_REQUEST,
  PROJECTS_LIST_SUCCESS,
  PROJECTS_LIST_FAIL,
  PROJECTS_DETAILS_REQUEST,
  PROJECTS_DETAILS_SUCCESS,
  PROJECTS_DETAILS_FAIL,
  PROJECT_CREATE_REVIEW_REQUEST,
  PROJECT_CREATE_REVIEW_SUCCESS,
  PROJECT_CREATE_REVIEW_FAIL,
} from "../constants/projectsConstants";

export const listProjects = (keyword = '') => async (dispatch) => {
  try {
    dispatch({ type: PROJECTS_LIST_REQUEST });
    const { data } = await axios.get(`/api/projects${keyword}`);

    dispatch({
      type: PROJECTS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROJECTS_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listProjectsDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PROJECTS_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/projects/${id}`);

    dispatch({
      type: PROJECTS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROJECTS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createProjectReview =
  (projectId, review) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROJECT_CREATE_REVIEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/projects/${projectId}/reviews/`,
        review,
        config
      );
      dispatch({
        type: PROJECT_CREATE_REVIEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROJECT_CREATE_REVIEW_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
