import React from 'react'
import { Card } from 'react-bootstrap'
function About() {
  return (
    <div className="container">
      <h1 class="text-center mt-4">About website?</h1>
   
      <h5>

      Website which helps lakhs of students to Learn Coding & Programming of latest technologies present in software development field. I teach code for free at my youtube channel ARKPROCODER which is authentic for many students to learn and create fully fludged projects this website provides more information on projects and provide blogging on technologies
      </h5>
      <hr />
<div className="row">
<h1 class=" mt-4">About me</h1>
<div className="col-md-6">
<Card border="light" style={{ width: '20rem' }}>
        <Card.Header>Details</Card.Header>
        <Card.Body>
          <Card.Title className='bg-dark p-2 text-white'> Name : Anees Rehman Khan | Full Stack Developer</Card.Title>
          <hr/>
          <Card.Header> Education</Card.Header>
          <Card.Text>
           <span><b>Degree :</b> </span> Graduated in Computer Science and Engineering
          </Card.Text>
          <Card.Text>
           <span><b>College :</b> </span>Sri Venkateshwara College Of Engineering Bangalore
          </Card.Text>
          <Card.Text>
           <span><b>Rank :</b> </span> First Class Distintion
          </Card.Text>
          <Card.Text>
           <span><b>CGPA :</b> </span> 8.0/10.0
          </Card.Text>
          <Card.Text>
           <span><b>Passed Out Year :</b> </span> 2021
          </Card.Text>
        </Card.Body>
      </Card>
</div>

<div className="col-md-6">
<Card border="light" style={{ width: '20rem' }}>
        <Card.Header>SSLC 10th</Card.Header>
        <Card.Body>
          <Card.Title>Maths, Science & etc</Card.Title>
          <hr/>
          <Card.Text>
           <span><b>School :</b> </span>Ruby English High School
          </Card.Text>
          <Card.Text>
           <span><b>Rank :</b> </span>  Distintion
          </Card.Text>
          <Card.Text>
           <span><b>Percentage :</b> </span> 85%
          </Card.Text>
          <Card.Text>
           <span><b>Passed Out Year :</b> </span> 2015
          </Card.Text>


          
        </Card.Body>
      </Card>
<Card border="light" style={{ width: '20rem' }}>
        <Card.Header>Languages Known</Card.Header>
        <Card.Body>
          
          <Card.Text>
           1. English 
          </Card.Text>
          <Card.Text>
           2. Hindi
          </Card.Text>
          <Card.Text>
          3. Kannada
          </Card.Text>
          <Card.Text>
         4. Urdu
          </Card.Text>


          
        </Card.Body>
      </Card>
</div>


</div>
<div className="row">

<div className="col-md-6">
<Card border="light" style={{ width: '20rem' }}>
        <Card.Header>P.U.C</Card.Header>
        <Card.Body>
          <Card.Title>P.C.M.B</Card.Title>
          <hr/>
          <Card.Text>
           <span><b>College :</b> </span>Pragathi Composite P.U College Vijayapura
          </Card.Text>
          <Card.Text>
           <span><b>Rank :</b> </span>  Distintion
          </Card.Text>
          <Card.Text>
           <span><b>Percentage :</b> </span> 88%
          </Card.Text>
          <Card.Text>
           <span><b>Passed Out Year :</b> </span> 2017
          </Card.Text>
        </Card.Body>
      </Card>
</div>
<div className="col-md-6">

<Card border="light" style={{ width: '20rem' }}>
        <Card.Header>Interest</Card.Header>
        <Card.Body>
          <Card.Text>1. Cricket</Card.Text>
        
          <Card.Text>
           2. Learning New Technologies
          </Card.Text>
          <Card.Text>
           3. Gym fitness
          </Card.Text>
          <Card.Text>
     4. Travel
          </Card.Text>
         
        </Card.Body>
      </Card>

</div>


</div>
   
<div className="row">

<div className="col-md-6">
<Card border="light" style={{ width: '20rem' }}>
        <Card.Header>Achievements</Card.Header>
        <Card.Body>
          <Card.Title>Rank Candidate @ school & Colleges</Card.Title>
          <hr/>
          <Card.Text>
           <span><b>Certified</b> </span> from infosys for Java Full stack
          </Card.Text>
          <Card.Text>
           <span><b>Certified :</b> </span>  at L.C.O for MERN Course
          </Card.Text>
          <Card.Text>
           <span><b>Certified  :</b> </span> from UDEMY for spring
          </Card.Text>
          <Card.Text>
           <span><b>Certified :</b> </span> from Udemy in Reactjs, Reduc, DJango rest framework
          </Card.Text>
        </Card.Body>
      </Card>
</div>
<div className="col-md-6">

<Card border="light" style={{ width: '20rem' }}>
        <Card.Header>Info</Card.Header>
        <Card.Body>
          <Card.Text>Country : India</Card.Text>
        
          <Card.Text>
          State : Karnataka
          </Card.Text>
          <Card.Text>
           City : Bangalore
          </Card.Text>
          <Card.Text>
           Email : arkprocoder@gmail.com
          </Card.Text>
         
        </Card.Body>
      </Card>

</div>


</div>
      <br />
    </div>
  )
}

export default About