import { USER_CONTACT_FAIL,USER_CONTACT_REQUEST,USER_CONTACT_SUCCESS,PROFILE_LIST_FAIL,PROFILE_LIST_SUCCESS,PROFILE_LIST_REQUEST } from '../constants/contactConstants'




export const userContactReducers = (state={  },action) =>{

    switch(action.type){
        case USER_CONTACT_REQUEST:
            return {loading:true}
        case USER_CONTACT_SUCCESS:
            return {loading:false,userInfo:action.payload}
        case USER_CONTACT_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}


export const profileListReducers = (state={ profiles:[] },action) =>{

    switch(action.type){
        case PROFILE_LIST_REQUEST:
            return {loading:true,profiles:[]}
        case PROFILE_LIST_SUCCESS:
            return {loading:false,profiles:action.payload}
        case PROFILE_LIST_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}