import { USER_CONTACT_FAIL,USER_CONTACT_REQUEST,USER_CONTACT_SUCCESS,PROFILE_LIST_REQUEST,PROFILE_LIST_SUCCESS,PROFILE_LIST_FAIL } from '../constants/contactConstants'
import axios from "axios";



export const contact =(name,email,pnumber,desc)=> async(dispatch)=>{
    try{

       dispatch({
           type:USER_CONTACT_REQUEST
       })

       const config = {
           headers:{
               'Content-type':'application/json'
           }
       }

       const {data}= await axios.post('/api/users/contact/',
       
       
       {'name':name,'email':email,'pnumber':pnumber,'desc':desc },config )

       dispatch({
           type:USER_CONTACT_SUCCESS,
           payload:data
       })
      

       

    }
    catch(error){

       dispatch({
           type:USER_CONTACT_FAIL,
           payload:error.response && error.response.data.detail
           ? error.response.data.detail
           :error.message,
       })

    }
}



export const listprofile =() => async (dispatch)=>{

    try{
        dispatch({type:PROFILE_LIST_REQUEST})
        const {data} = await axios.get('/api/users/getProfile/')

        dispatch({
            type:PROFILE_LIST_SUCCESS,
            payload:data
        })

    }
    catch(error){
        dispatch({
            type:PROFILE_LIST_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            :error.message,
        })

    }
}