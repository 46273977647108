import React, {useEffect } from 'react'
import Loader from '../Loader';
import Message from '../Message';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { listWork } from '../../actions/workAction';
import Works from '../Works';




function ProjectsScreen() {
  const dispatch = useDispatch();
  const workList = useSelector((state) => state.workList);
  const { error, loading, work } = workList;


  useEffect(() => {
    dispatch(listWork())

  }, [dispatch]);



  return (
    <>
    <h1 className='text-center'>Work Experience</h1>

    {loading ? (
           <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Row className='m-3'>
              {work.map((workdata) => (
                <Col key={workdata.id} sm={12} md={6} lg={4} xl={3}>
                
                  <Works workdata={workdata} />
                </Col>
              ))}
            </Row>
          )}
</>    
  )
}

export default ProjectsScreen