import React ,{useState,useEffect}from 'react'
import { Container } from "react-bootstrap";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,useLocation  } from 'react-router-dom';
import Message from "../Message";
import Loader from "../Loader";
import { getUserDetails,updateUserProfile } from "../../actions/userAction";
import { USER_UPDATE_PROFILE_RESET } from '../../constants/userConstants';
import { enrollDetails } from '../../actions/enrollAction';
import { WORKSHOP_ENROLL_REQUEST_DETAILS } from '../../constants/enrollConstants';

function ProfileScreen() {
  const navigate = useNavigate();
  const [name,setName]=useState('')
  const [email,setEmail]=useState('')
  const [password,setPassword]=useState('')
  const [confirmPassword,setConfirmPassword]=useState('')
  const [message,setMessage]=useState('')
  const dispatch = useDispatch()
  
  const userDetails = useSelector(state=>state.userDetails)
  const {error,loading,user}=userDetails

  const userLogin = useSelector(state=>state.userLogin)
  const {userInfo}=userLogin

  const userUpdateProfile = useSelector(state=>state.userUpdateProfile)
  const { success }=userUpdateProfile
  


  const workshopregisterDetails = useSelector(state=>state.workshopregisterDetails)
  const { workshopInfo }=workshopregisterDetails
 
  

  function refresh(){
        window.location.reload("Refresh")
      }


  useEffect(()=>{
    console.log(user.id)
    if(!userInfo){
        navigate('/signin')
    }
    else{
      if(!user || !user.name || success ){
        dispatch({type:USER_UPDATE_PROFILE_RESET})
        dispatch(getUserDetails('profile'))
        dispatch(enrollDetails())

      }
      else
      {
        setName(user.name)
        setEmail(user.email)
        // dispatch(enrollDetails())

      }

      
    }


},[dispatch,user,success,userInfo,workshopInfo])

const submitHandler= (e)=>{
  e.preventDefault()
  if(password!==confirmPassword){
      setMessage('Password do not Match')
      navigate('/register')
  }
  else{
  dispatch(updateUserProfile({'id':user._id,'name':name,'email':email,'password':password}))
  setMessage('')
  
  }
 
}
  return (
   <Container> 
  <Row>
      <Col md={3}>
        <h2>User Profile</h2>
        <Card>
              <Card.Header as="h1" className="text-center bg-dark text-light">
                Update..        
              </Card.Header>              
              <Card.Body>
              {message && <Message variant='danger'>{message}</Message>}
         
          {loading && <Loader />}
        
                <Form onSubmit={submitHandler}>

                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Enter Name"
                      value={name} onChange={(e)=> setName(e.target.value)} 
                      required
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>Email Address </Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder="Enter Email"
                      value={email} onChange={(e)=> setEmail(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      
                      type="password"
                      placeholder="Enter Password"
                      value={password} onChange={(e)=> setPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="password1">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      
                      type="password"
                      placeholder="Confirm Password"
                      value={confirmPassword} onChange={(e)=> setConfirmPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <br />
                  <div className="d-grid gap-2">
                    <Button
                      className="btn btn-md btn-light"
                      variant="light"
                      type="submit"
                    >
                      Update
                    </Button>
                  </div>
                </Form>

                
              </Card.Body>
            </Card>
      
      </Col>

      {workshopInfo ? (
       <Col md={5}> 
       <br />
       <h3 className='text-center'>Enrollment Details <i className="fa-solid fa-person fa-bounce"></i></h3>
 
 
       <table className="table table-hover">
   <thead>
     <tr>
       <th scope="col">Info</th>
       <th scope="col">Data</th>
      
     </tr>
   </thead>
   <tbody>
     
    
     <tr className="table-info">
       <th scope="row">Name</th>
       <td>{workshopInfo.name}</td>
     </tr>
    
 
   
     <tr className="table-info">
       <th scope="row">Email</th>
       <td>{workshopInfo.email}</td>
     </tr>
   
     <tr className="table-info">
       <th scope="row">Course</th>
       <td>{workshopInfo.course} <i className="fa-brands fa-python fa-beat"></i></td>
     </tr>
   
     <tr className="table-info">
       <th scope="row">Roll No.</th>
       <td>{workshopInfo.usn}</td>
     </tr>
     <tr className="table-info">
       <th scope="row">College Name</th>
       <td>{workshopInfo.collegename} <i className="fa-solid fa-graduation-cap fa-beat-fade"></i></td>
     </tr>
     <tr className="table-info">
       <th scope="row">State</th>
       <td>{workshopInfo.state}</td>
     </tr>
   
     <tr className="table-info">
       <th scope="row">Gender</th>
       <td>{workshopInfo.gender}</td>
     </tr>
   
     <tr className="table-info">
       <th scope="row">Phone</th>
       <td>{workshopInfo.phonenumber}</td>
     </tr>
 
     <tr className="table-info">
       <th scope="row">Payment ID</th>
       <td>{workshopInfo.wid}</td>
     </tr>

     <tr className="table-info">
       <th scope="row">Verified</th>
       {workshopInfo.active ? <th scope="row">Yes <i className="fa-solid fa-thumbs-up fa-beat"></i></th> : <th scope="row">No <i className="fa-solid fa-thumbs-down fa-beat-fade"></i></th>}
     </tr>
     <tr className="table-info">
       <th scope="row">Paid</th>
       {workshopInfo.paid ? <th scope="row">Yes <i className="fa-solid fa-thumbs-up fa-beat"></i></th> : <th scope="row">No <i className="fa-solid fa-thumbs-down fa-beat-fade"></i></th>}
     </tr>
     <tr className="table-info">
       <th scope="row">Certificate Number</th>
       <td>Not Updated</td>
     </tr>
     <tr className="table-info">
       <th scope="row">Certificate Link</th>
       <td>Not Updated</td>
     </tr>
   
   </tbody>
 </table>
 
 
      
       </Col>
        
      ):(
        <div>
          <br />
          <h1>Click Below</h1> 
          <p><input type="button" className='btn btn-sm btn-info' value="View Enrollment Details" onClick={refresh} /></p>
        </div>
        
      )}
    </Row>

   </Container>
  )


}

export default ProfileScreen