import React, { useState } from "react";
import {
  Navbar,
  NavDropdown,
  Nav,
  Container,
  Offcanvas,
  Form,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch,useSelector } from 'react-redux'


import { LinkContainer } from "react-router-bootstrap";
import { logout } from "../actions/userAction";
import Message from "./Message";
import { useNavigate  } from 'react-router-dom';

function Header() {
const [mode, setDarkMode] = useState("light");
const [imode, setiDarkMode] = useState("");
const navigate = useNavigate();
const [message,setMessage]=useState('')
const userLogin = useSelector(state=>state.userLogin)
const {userInfo} = userLogin
const dispatch = useDispatch()
const logoutHandler = () => {
  dispatch(logout())
  setMessage('Logout Success..');  
  navigate('/signin')
  

}
const toogleMode = () => {
  if (mode === "light") {
    setDarkMode("black");
    setiDarkMode("light");
    document.body.style.backgroundColor = "#282a36";
    document.body.style.color="#ff79c6";
 
    

  } else {
    setDarkMode("light");
    setiDarkMode("");
    document.body.style.backgroundColor ="rgb(255, 255, 255)";
    document.body.style.color="rgb(85, 89, 92)";
  
   
  }
  
};
  const expand = "lg";
  return (
    <>
     
    <Nav className={`justify-content-end  navbar-${mode} bg-${mode}`}>

  
    <Nav.Item>
      <Nav.Link target="_blank" href="https://in.linkedin.com/in/aneesrehmankhan"><i id="sicon"  className={`fa-brands fa-linkedin bg-${imode}`}></i></Nav.Link>
      
    </Nav.Item>
    
    <Nav.Item>
    <Nav.Link  target="_blank"  href="https://github.com/arkprocoder"><i id="sicon" className={`fa-brands fa-square-github bg-${imode}`}></i></Nav.Link>
    </Nav.Item>

    <Nav.Item>
      <Nav.Link target="_blank" href="https://www.instagram.com/anees_rehman_khan_/"><i  id="sicon"  className={`fa-brands fa-instagram bg-${imode}`}></i></Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link target="_blank" href="https://www.facebook.com/aneesurrehman.aneesrehman/"><i  id="sicon" className={`fa-brands fa-facebook bg-${imode}`}></i></Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link target="_blank" href="https://www.youtube.com/@ARKPROCODER"><i  id="sicon" className={`fa-brands fa-youtube bg-${imode}`}></i></Nav.Link>
    </Nav.Item>
    <Nav.Item>
    </Nav.Item>
  </Nav>

      <Navbar key={expand} bg={`${mode}`} variant={`${mode}`} expand={expand} className="mb-1" collapseOnSelect>
        <Container fluid>
        <LinkContainer to="/">
          <Navbar.Brand className={`text-${imode}`}>   <img
              src="https://arkprocoder.com/static/ark.png" width="50px"
            />A.R.K <i className="fa-sharp fa-solid fa-code" ></i></Navbar.Brand>
        </LinkContainer>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className={`bg-${imode} text-${mode}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton className={`bg-${imode} text-${mode}`}>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`  } >
                Select Menu
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className={`bg-${mode} text-${imode}`}>
              <Nav className="justify-content-end flex-grow-1 pe-3"  >
              <LinkContainer to="/">
                <Nav.Link className={`text-${imode}`}>
                <i className="fa-solid fa-house-user"></i> Home
                </Nav.Link>
              </LinkContainer>


              <LinkContainer to="/projects">
                <Nav.Link className={`text-${imode}`}>
                <i className="fa-solid fa-graduation-cap"></i> Projects
                </Nav.Link>
              </LinkContainer>

              <LinkContainer to="/youtube">
                <Nav.Link className={`text-${imode}`}>
                <i className="fa-solid fa-graduation-cap"></i> Courses
                </Nav.Link>
              </LinkContainer>

              <LinkContainer to="/blog">
                <Nav.Link className={`text-${imode}`}>
                <i className="fa-solid fa-blog"></i> Blog
                </Nav.Link>
              </LinkContainer>

              <LinkContainer to="/workshop">
                <Nav.Link className={`text-${imode}`}>
                <i className="fa-solid fa-code"></i> Workshop
                </Nav.Link>
              </LinkContainer>

             


              <NavDropdown title="Connect Me">
               <LinkContainer to='/contact/'>
               <NavDropdown.Item className={`text-${imode}`}><i className="fa-solid fa-address-card"></i> Contact</NavDropdown.Item>
               </LinkContainer>
               <LinkContainer to='/about/'>
               <NavDropdown.Item className={`text-${imode}`}>  <i className="fas fa-user"></i> About</NavDropdown.Item>
               </LinkContainer>

               
              <LinkContainer to="/workexperience">
                <NavDropdown.Item className={`text-${imode}`}>
               <i className="fa-solid fa-briefcase"></i> Experience
                </NavDropdown.Item>
              </LinkContainer>

             </NavDropdown>





              {userInfo ? (
               <NavDropdown title={userInfo.name} id='username'>
               <LinkContainer to='/updateprofile/'>
                   <NavDropdown.Item className={`text-${imode}`}>Profile</NavDropdown.Item>
               </LinkContainer>

               <NavDropdown.Item className={`text-${imode}`} onClick={logoutHandler}>Logout</NavDropdown.Item>

           </NavDropdown>
       ) : (
               <LinkContainer to='/signin'>
                   <Nav.Link className={`text-${imode}`}><i className="fa-solid fa-right-to-bracket"></i>Login</Nav.Link>
                  
               </LinkContainer>
           )}
           
          <div className="form-check form-switch mt-2">
          
        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onClick={toogleMode} /><i className="fa-solid fa-moon"></i>
        <label className={`form-check-label text-${mode==='light'?'dark':'light'}`} htmlFor="flexSwitchCheckDefault">{mode==='light'?'light':'Dark'} Mode</label>
      </div>

               
              </Nav>
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                />
              <Link to="/projects">  <Button variant={`outline-${imode}`}>Search</Button></Link>
              </Form>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      { message && <Message variant='danger'>{message}</Message> }
     
          
           
    </>
  );
}

export default Header;
