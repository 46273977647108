import {
    BLOGS_LIST_REQUEST,BLOGS_LIST_SUCCESS,
BLOGS_LIST_FAIL,
    BLOGS_DETAILS_REQUEST,BLOGS_DETAILS_SUCCESS,
BLOGS_DETAILS_FAIL,

} from '../constants/blogConstants'


export const blogsListReducers = (state={ blogs:[] },action) =>{

    switch(action.type){
        case BLOGS_LIST_REQUEST:
            return {loading:true,blogs:[]}
        case BLOGS_LIST_SUCCESS:
            return {loading:false,blogs:action.payload}
        case BLOGS_LIST_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}

export const blogsDetailsReducers = (state={ blog: [] },action) =>{

    switch(action.type){
      
        case BLOGS_DETAILS_REQUEST:
            return {loading:true,...state}
        case BLOGS_DETAILS_SUCCESS:
            return {loading:false,blog:action.payload}
        case BLOGS_DETAILS_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}


