import {
    WORKSHOP_LIST_REQUEST,WORKSHOP_LIST_SUCCESS,
WORKSHOP_LIST_FAIL,
    WORKSHOP_DETAILS_REQUEST,WORKSHOP_DETAILS_SUCCESS,
WORKSHOP_DETAILS_FAIL


} from '../constants/workshopConstants'


export const workshopsListReducers = (state={ workshops:[] },action) =>{

    switch(action.type){
        case WORKSHOP_LIST_REQUEST:
            return {loading:true,workshops:[]}
        case WORKSHOP_LIST_SUCCESS:
            return {loading:false,workshops:action.payload}
        case WORKSHOP_LIST_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}

export const workshopDetailsReducers = (state={ workshop:[] },action) =>{

    switch(action.type){
      
        case WORKSHOP_DETAILS_REQUEST:
            return {loading:true,...state}
        case WORKSHOP_DETAILS_SUCCESS:
            return {loading:false,workshop:action.payload}
        case WORKSHOP_DETAILS_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}