import React, { useState,useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Row, Col, Button,Form ,Card } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { login } from '../../actions/userAction';
import Message from '../Message';
import Loader from '../Loader';
import { useNavigate,useLocation  } from 'react-router-dom';
function Signin({}) {
  
  const [message,setMessage]=useState('')
  const navigate = useNavigate();
  const [email,setEmail]=useState('')
  const [password,setPassword]=useState('')
  const dispatch = useDispatch()
  const userLogin = useSelector(state=>state.userLogin)
  const {error,loading,userInfo}=userLogin
  // const redirect = '/'
  const location = useLocation();
  // console.log(location)
  const redirect = location.search ? location.search.split('=')[1] :'/'
  // console.log(redirect)
  useEffect(()=>{
    if(userInfo){
        navigate('/')
    }
},[userInfo,redirect])

const submitHandler= (e)=>{
  e.preventDefault()
  dispatch(login(email,password))


}


  return (
  <>
  <br />
  <Container className='mt-5' >
 <Row>
    <Col md={4}></Col>
    <Col md={4}>
    <Card>
    <Card.Header  as="h1" className='text-center bg-black text-light'>Signin</Card.Header>
    {error && <Message variant='danger'>{error}</Message>}
          {loading && <Loader />}
    <Card.Body>
<Form onSubmit={submitHandler}>
{message && <Message variant='success'>{message}</Message>}

<Form.Group controlId='email'>
  <Form.Label>Email Address </Form.Label>
  <Form.Control type='email' placeholder='Enter Email' value={email} onChange={(e)=> setEmail(e.target.value)} required></Form.Control>
</Form.Group>

<Form.Group controlId='password'>
  <Form.Label>Password</Form.Label>
  <Form.Control type='password' placeholder='Enter Password' value={password} onChange={(e)=> setPassword(e.target.value)} required></Form.Control>
</Form.Group>
<br />
<div className="d-grid gap-2">
  <Button className="btn btn-md btn-success" variant='success' type="submit">Sign In</Button>

</div>

</Form>

<Row className='py-3'>
<Col>
New User? 
<Link to="/register">Register</Link>
</Col>

</Row>
</Card.Body>

</Card>



    </Col>
    <Col md={4}></Col>
 </Row>
  </Container>
  </>
  )
}

export default Signin