import {
    SERVICES_LIST_REQUEST,SERVICES_LIST_SUCCESS,
SERVICES_LIST_FAIL,
    SERVICES_DETAILS_REQUEST,SERVICES_DETAILS_SUCCESS,
SERVICES_DETAILS_FAIL


} from '../constants/servicesConstants'


export const servicesListReducers = (state={ services:[] },action) =>{

    switch(action.type){
        case SERVICES_LIST_REQUEST:
            return {loading:true,services:[]}
        case SERVICES_LIST_SUCCESS:
            return {loading:false,services:action.payload}
        case SERVICES_LIST_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}

export const servicesDetailsReducers = (state={ service:[] },action) =>{

    switch(action.type){
      
        case SERVICES_DETAILS_REQUEST:
            return {loading:true,...state}
        case SERVICES_DETAILS_SUCCESS:
            return {loading:false,service:action.payload}
        case SERVICES_DETAILS_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}