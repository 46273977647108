export const PROJECTS_LIST_REQUEST= 'PROJECTS_LIST_REQUEST'
export const PROJECTS_LIST_SUCCESS ='PROJECTS_LIST_SUCCESS'
export const PROJECTS_LIST_FAIL = 'PROJECTS_LIST_FAIL'

export const PROJECTS_DETAILS_REQUEST= 'PROJECTS_DETAILS_REQUEST'
export const PROJECTS_DETAILS_SUCCESS ='PROJECTS_DETAILS_SUCCESS'
export const PROJECTS_DETAILS_FAIL = 'PROJECTS_DETAILS_FAIL'


export const PROJECT_CREATE_REVIEW_REQUEST= 'PROJECT_CREATE_REVIEW_REQUEST'
export const PROJECT_CREATE_REVIEW_SUCCESS ='PROJECT_CREATE_REVIEW_SUCCESS'
export const PROJECT_CREATE_REVIEW_FAIL = 'PROJECT_CREATE_REVIEW_FAIL'
export const PROJECT_CREATE_REVIEW_RESET = 'PROJECT_CREATE_REVIEW_RESET'


