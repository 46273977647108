import {
    PROJECTS_LIST_REQUEST,PROJECTS_LIST_SUCCESS,
PROJECTS_LIST_FAIL,
    PROJECTS_DETAILS_REQUEST,PROJECTS_DETAILS_SUCCESS,
PROJECTS_DETAILS_FAIL,
PROJECT_CREATE_REVIEW_REQUEST,
PROJECT_CREATE_REVIEW_SUCCESS,
PROJECT_CREATE_REVIEW_FAIL,
PROJECT_CREATE_REVIEW_RESET,
} from '../constants/projectsConstants'


export const projectsListReducers = (state={ projects:[] },action) =>{

    switch(action.type){
        case PROJECTS_LIST_REQUEST:
            return {loading:true,projects:[]}
        case PROJECTS_LIST_SUCCESS:
            return {loading:false,projects:action.payload}
        case PROJECTS_LIST_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}

export const projectsDetailsReducers = (state={ proj: { reviews: [] } },action) =>{

    switch(action.type){
      
        case PROJECTS_DETAILS_REQUEST:
            return {loading:true,...state}
        case PROJECTS_DETAILS_SUCCESS:
            return {loading:false,proj:action.payload}
        case PROJECTS_DETAILS_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}


export const projectReviewCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PROJECT_CREATE_REVIEW_REQUEST:
            return { loading: true }

        case PROJECT_CREATE_REVIEW_SUCCESS:
            return { loading: false, success: true, }

        case PROJECT_CREATE_REVIEW_FAIL:
            return { loading: false, error: action.payload }

        case PROJECT_CREATE_REVIEW_RESET:
            return {}

        default:
            return state
    }
}