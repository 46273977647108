import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { HashRouter as Router, Routes , Route } from 'react-router-dom';
import HomeScreen from "./components/screens/HomeScreen";
import About from "./components/screens/About";
import Contact from "./components/screens/Contact";
import Blog from "./components/screens/Blog";
import Signin from "./components/screens/Signin";
import Register from "./components/screens/Register";
import ProfileScreen from "./components/screens/ProfileScreen";
import TechScreen from "./components/screens/TechScreen";
import Services from "./components/screens/ServicesScreen";
import ProjectsScreen from "./components/screens/ProjectsScreen";
import WorkScreen from "./components/screens/WorkScreen";
import ProjectsData from "./components/ProjectsData";
import PlaylistScreen from "./components/screens/PlaylistScreen";
import YoutubePlaylistScreen from "./components/screens/YoutubePlaylistScreen";
import BlogsScreen from "./components/screens/BlogsScreen";
import Privacypolicy from "./components/screens/Privacypolicy";
import WorkshopScreen from "./components/screens/WorkshopScreen";
import WorkshopsData from "./components/WorkshopsData";
import Enroll from "./components/screens/Enroll";





function App() {
  return (
  

   <Router>
      <Header/>
    <Routes>
      <Route exact path="/"  element={<HomeScreen />}></Route>
      <Route exact path="/about"  element={<About />}></Route>
      <Route exact path="/contact"  element={<Contact />}></Route>
      <Route exact path="/blog"  element={<Blog />}></Route>
      <Route exact path="/blog/:slug"  element={<BlogsScreen  />}></Route>
      <Route exact path="/signin"  element={<Signin />}></Route>
      <Route exact path="/register"  element={<Register />}></Route>
      <Route exact path="/updateprofile"  element={<ProfileScreen />}></Route>
      <Route exact path="/technology/:id"  element={<TechScreen />}></Route>
      <Route exact path="/service/:id"  element={<Services />}></Route>
      <Route exact path="/projects"  element={<ProjectsScreen />}></Route>
      <Route exact path="/workexperience"  element={<WorkScreen />}></Route>
      <Route exact path="/project/:id"  element={<ProjectsData />}></Route>
      <Route exact path="/youtube"  element={<PlaylistScreen />}></Route>
      <Route exact path="/youtube/:id"  element={<YoutubePlaylistScreen />}></Route>
      <Route exact path="/privacy"  element={<Privacypolicy />}></Route>
      <Route exact path="/workshop"  element={<WorkshopScreen />}></Route>
      <Route exact path="/workshop/:id"  element={<WorkshopsData />}></Route>
      <Route exact path="/enroll"  element={<Enroll />}></Route>

      
    </Routes>
  
    <Footer />
  </Router>
  

  );
}

export default App;
