import {
    TECHNOLOGY_LIST_REQUEST,TECHNOLOGY_LIST_SUCCESS,
TECHNOLOGY_LIST_FAIL,
    TECHNOLOGY_DETAILS_REQUEST,TECHNOLOGY_DETAILS_SUCCESS,
TECHNOLOGY_DETAILS_FAIL


} from '../constants/techConstants'


export const technologysListReducers = (state={ techs:[] },action) =>{

    switch(action.type){
        case TECHNOLOGY_LIST_REQUEST:
            return {loading:true,techs:[]}
        case TECHNOLOGY_LIST_SUCCESS:
            return {loading:false,techs:action.payload}
        case TECHNOLOGY_LIST_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}

export const technologyDetailsReducers = (state={ tech:[] },action) =>{

    switch(action.type){
      
        case TECHNOLOGY_DETAILS_REQUEST:
            return {loading:true,...state}
        case TECHNOLOGY_DETAILS_SUCCESS:
            return {loading:false,tech:action.payload}
        case TECHNOLOGY_DETAILS_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}