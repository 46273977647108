export const WORKSHOP_ENROLL_REQUEST= 'WORKSHOP_ENROLL_REQUEST'
export const WORKSHOP_ENROLL_SUCCESS ='WORKSHOP_ENROLL_SUCCESS'
export const WORKSHOP_ENROLL_FAIL = 'WORKSHOP_ENROLL_FAIL'


export const WORKSHOP_ENROLL_REQUEST_DETAILS= 'WORKSHOP_ENROLL_REQUEST_DETAILS'
export const WORKSHOP_ENROLL_SUCCESS_DETAILS ='WORKSHOP_ENROLL_SUCCESS_DETAILS'
export const WORKSHOP_ENROLL_FAIL_DETAILS = 'WORKSHOP_ENROLL_FAIL_DETAILS'


