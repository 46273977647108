import React, {useEffect } from 'react'
import Loader from '../Loader';
import Message from '../Message';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useLocation } from 'react-router-dom'
import { listWorkshops } from '../../actions/workshopActions';
import Workshop from '../Workshop';




function WorkshopScreen() {
  const dispatch = useDispatch();
  const workshopsList = useSelector((state) => state.workshopsList);
  const { error, loading, workshops } = workshopsList;

  const history = useLocation();
  let keyword =  history.search;

  useEffect(() => {
    dispatch(listWorkshops(keyword))

  }, [dispatch,keyword]);



  return (
    <>
    {loading ? (
           <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Row className='m-3'>
              {workshops.map((workshop) => (
                <Col key={workshop._id} sm={12} md={12} lg={12} xl={12}>
                
                  <Workshop workshop={workshop} />
                </Col>
              ))}
            </Row>
          )}
</>    
  )
}

export default WorkshopScreen