import { WORKSHOP_ENROLL_REQUEST,WORKSHOP_ENROLL_SUCCESS,WORKSHOP_ENROLL_FAIL,
    WORKSHOP_ENROLL_REQUEST_DETAILS,WORKSHOP_ENROLL_SUCCESS_DETAILS,WORKSHOP_ENROLL_FAIL_DETAILS } from '../constants/enrollConstants'
import axios from "axios";
import { USER_DETAILS_REQUEST } from '../constants/userConstants';



export const enroll =(name,email,course,usn,collegename,state,gender,phonenumber)=> async(dispatch)=>{
    try{

       dispatch({
           type:WORKSHOP_ENROLL_REQUEST
       })

       const config = {
           headers:{
               'Content-type':'application/json'
           }
       }

       const {data}= await axios.post('/api/register/',
       
       
       {'name':name,'email':email,'course':course,'usn':usn,'collegename':collegename,'state':state,'gender':gender,'phonenumber':phonenumber},config )

       dispatch({
           type:WORKSHOP_ENROLL_SUCCESS,
           payload:data
       })
      

       

    }
    catch(error){

       dispatch({
           type:WORKSHOP_ENROLL_FAIL,
           payload:error.response && error.response.data.detail
           ? error.response.data.detail
           :error.message,
       })

    }
}


export const enrollDetails = () => async (dispatch,getState) => {
    try{

        dispatch({
            type:USER_DETAILS_REQUEST
        })
        const {
         userLogin:{userInfo}
        } = getState()
 
       
        dispatch({
            type:WORKSHOP_ENROLL_REQUEST_DETAILS
        })

        
        
        const config = {
            headers:{
                'Content-type':'application/json',
                Authorization:`Bearer ${userInfo.token}`
            }
        }
        const {data}= await axios.get(`/api/registerdetails`,config) 
        dispatch({
            type:WORKSHOP_ENROLL_SUCCESS_DETAILS,
            payload:data
        })
   
 
     }
     catch(error){
 
        dispatch({
            type:WORKSHOP_ENROLL_FAIL_DETAILS,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            :error.message,
        })
 
     }
 }