import {
    WORK_LIST_REQUEST,WORK_LIST_SUCCESS,WORK_LIST_FAIL


} from '../constants/workConstants'


export const workListReducers = (state={ work:[] },action) =>{

    switch(action.type){
        case WORK_LIST_REQUEST:
            return {loading:true,work:[]}
        case WORK_LIST_SUCCESS:
            return {loading:false,work:action.payload}
        case WORK_LIST_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}
