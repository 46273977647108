import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Youtube({ youtubeplaylist }) {
  return (
    <>


      <Card className="my-3 p-3 rounded">
        <Card.Text as="div">
          <Link to={`/youtube/${youtubeplaylist._id}`}>
            <Card.Img
              src={youtubeplaylist.playlistLogo}
              style={{
                height: "auto",
                width: "50%",
                margin: "auto",
                display: "block",
              }}
              fuild
            />
          </Link>
        </Card.Text>

        <Card.Body>
          <Card.Title className="text-center">
            <h4>{youtubeplaylist.playlistName}</h4>
          </Card.Title>
        </Card.Body>

        <Link
          style={{ "text-decoration": "none" }}
          to={`/youtube/${youtubeplaylist._id}`}
        >
          <Button
            className="btn btn-sm btn-outline-danger"
            variant="light"
            style={{ margin: "auto", display: "block" }}
          >
            View <i className="fa-brands fa-youtube"></i>
          </Button>
        </Link>
      </Card>
    </>
  );
}

export default Youtube;
