import React, {useEffect } from 'react'
import Projects from '../Projects';
import Loader from '../Loader';
import Message from '../Message';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { listProjects } from '../../actions/projectsAction';
import SearchBox from '../SearchBox';
import { useLocation } from 'react-router-dom'




function ProjectsScreen() {
  const dispatch = useDispatch();
  const projectsList = useSelector((state) => state.projectsList);
  const { error, loading, projects } = projectsList;

  const history = useLocation();
  let keyword =  history.search;
  console.log(keyword);

  useEffect(() => {
    dispatch(listProjects(keyword))

  }, [dispatch,keyword]);



  return (
    <>
   

    <SearchBox />
    {loading ? (
           <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Row className='m-3'>
              {projects.map((proj) => (
                <Col key={proj._id} sm={12} md={6} lg={4} xl={3}>
                
                  <Projects proj={proj} />
                </Col>
              ))}
            </Row>
          )}
</>    
  )
}

export default ProjectsScreen