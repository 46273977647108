import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Message from "../Message";
import Loader from "../Loader";
import { register } from "../../actions/userAction";
import InputGroup from "react-bootstrap/InputGroup";
import { validEmail, validPassword } from "./Regex";
import { Alert } from 'react-bootstrap'
function Register() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [show, changeshow] = useState("fa fa-eye");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const redirect = location.search ? location.search.split("=")[1] : "/";
  const userRegister = useSelector((state) => state.userRegister);
  const { error, loading, userInfo } = userRegister;




  useEffect(() => {
    if (userInfo) {
      navigate("/enroll");
    }
  }, [userInfo, redirect]);

  const submitHandler = (e) => {
    
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Password do not Match");
      navigate("/register");
      
      
    } 

    else if (!validEmail.test(email)) {
      setMessage("Invalid Email");
    
   }
   else if (!validPassword.test(password)) {
    
      setMessage("Password Criteria not match");
     
   }
    
    else {
      dispatch(register(name, email, password));
      setMessage("Signin Success");
      navigate("/enroll");
    }
  };

  const showPassword = () => {
    var x = document.getElementById("pass1");
    var z = document.getElementById("pass2");
    if (x.type === "password" && z.type === "password") {
      x.type = "text";
      z.type = "text";
      changeshow(`fa fa-eye`);
    } else {
      x.type = "password";
      z.type = "password";
      changeshow(`fa fa-eye-slash`);
    }
  };

  return (
    <>
      <br />
      <Container className="mt-2">
        <Row>
          <Col md={4}></Col>
          <Col md={4}>
            <Card>
              <Card.Header as="h1" className="text-center bg-black text-light">
                Register
              </Card.Header>
              <Card.Body>
             
          {message && <Alert variant={"warning"}>
            {message}
        </Alert>}


                {error && <Message variant="warning">{error}</Message>}
                {loading && <Loader />}
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId="name">
                    <Form.Label>
                      <span>
                        <i className="fa fa-user"></i>{" "}
                      </span>
                      Name
                    </Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>
                      {" "}
                      <span>
                        <i className="fa-regular fa-envelope"></i>
                      </span>{" "}
                      Email Address{" "}
                    </Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <small>Password must include atleast [1-9][a-z][A-z][_$@*!..] & 5 Characters</small>
                  <Form.Group>
                    <Form.Label>
                      <span onClick={showPassword}>
                        <i className={show}></i>
                      </span>{" "}
                      Password{" "}
                    </Form.Label>

                    <InputGroup className="m-3">
                      <InputGroup.Checkbox onClick={showPassword} />
                      
                        {" "}
                        <Form.Control
                          placeholder="Enter Password"
                          required
                          type="password"
                          id="pass1"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      
                    </InputGroup>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>
                      <span onClick={showPassword}>
                        <i className={show}></i>
                      </span>{" "}
                      Confirm Password{" "}
                    </Form.Label>
                    <InputGroup className="m-3">
                      <InputGroup.Checkbox onClick={showPassword} />
                    
                        <Form.Control
                          required
                          type="password"
                          id="pass2"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        ></Form.Control>
                 
                    </InputGroup>
                  </Form.Group>
                  <br />
                  <div className="d-grid gap-2">
                    <Button
                      className="btn btn-md btn-success"
                      variant="success"
                      type="submit"
                     
                    >
                      Register
                    </Button>

                   
                  </div>
                </Form>

                <Row className="py-3">
                  <Col>
                    Already User?
                    <Link to="/signin">Sign In</Link>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}></Col>
        </Row>
      </Container>
    </>
  );
}

export default Register;
