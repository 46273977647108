import React,{useState} from 'react'
import { Row, Col, Button,Form ,Card,InputGroup } from "react-bootstrap";
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { contact } from "../../actions/contactAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate  } from 'react-router-dom';
import Message from '../Message';
import Loader from '../Loader';

function Contact() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [pnumber, setPnumber] = useState('');
    const [desc, setDesc] = useState('');



  const submitHandler = (e) => {
    e.preventDefault()
        dispatch(contact(name, email, pnumber,desc))
        setName('');
        setEmail('');
        setDesc('');
        setPnumber('');
        navigate('/contact')
    }

    const contactRegister = useSelector(state=>state.contactRegister)
    const {error,loading}=contactRegister


  return (
    <>
    <br />
    <Container className='mt-5' >
   <Row>
      <Col md={4}></Col>
      <Col md={4}>
      <Card>
      <Card.Header  as="h1" className='text-center bg-dark text-light'>Contact</Card.Header>
     
      <Card.Body>
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}
  <Form  onSubmit={submitHandler}>

  
  <Form.Group controlId='name'  className="mb-3">
    
    <Form.Control type='text' placeholder='Enter Name' value={name} onChange={(e) => setName(e.target.value)} required></Form.Control>
  </Form.Group>
  <Form.Group controlId='email'  className="mb-3">

    <Form.Control type='email' value={email} placeholder='Enter Email' onChange={(e) => setEmail(e.target.value)} required></Form.Control>
  </Form.Group>
  <Form.Group controlId='phone'  className="mb-3">

    <Form.Control type='number' value={pnumber} placeholder='Enter Phone Number' onChange={(e) => setPnumber(e.target.value)} required></Form.Control>
  </Form.Group>
  
  <InputGroup size="sm"  className="mb-3">
        <InputGroup.Text>How can i Help You? </InputGroup.Text>
        <Form.Control as="textarea" value={desc} aria-label="With textarea" onChange={(e) => setDesc(e.target.value)} placeholder='Enter text' required/>
    </InputGroup>
  <br />
  <div className="d-grid gap-2">
    <Button className="btn btn-outline-success" variant='light' type="submit">Submit</Button>
  
  </div>
  
  </Form>
  
  <Row className='py-3'>
  <Col>
  New User? 
  <Link to="/register">Register</Link>
  </Col>
  
  </Row>
  </Card.Body>
  
  </Card>
  
  
  
      </Col>
      <Col md={4}></Col>
   </Row>
    </Container>
    </>
  )
}

export default Contact