import axios from 'axios'
import {
    SERVICES_LIST_REQUEST,SERVICES_LIST_SUCCESS,
SERVICES_LIST_FAIL,
    SERVICES_DETAILS_REQUEST,SERVICES_DETAILS_SUCCESS,
SERVICES_DETAILS_FAIL


} from '../constants/servicesConstants'


export const listServices =() => async (dispatch)=>{

    try{
        dispatch({type:SERVICES_LIST_REQUEST})
        const {data} = await axios.get('/api/services/')

        dispatch({
            type:SERVICES_LIST_SUCCESS,
            payload:data
        })

    }
    catch(error){
        dispatch({
            type:SERVICES_LIST_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            :error.message,
        })

    }
}

export const listServicesDetails =(id) => async (dispatch)=>{

    try{
        dispatch({type:SERVICES_DETAILS_REQUEST})
        const {data} = await axios.get(`/api/services/${id}`)

        dispatch({
            type:SERVICES_DETAILS_SUCCESS,
            payload:data
        })

    }
    catch(error){
        dispatch({
            type:SERVICES_DETAILS_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            :error.message,
        })

    }
}