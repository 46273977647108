import React from "react";
import { Card,Button } from "react-bootstrap";
import {Link} from "react-router-dom"


function Projects({proj}) {
  const frt=proj.frontend;
  const bcd=proj.backend;
  const dbs=proj.dbs;


  return (

   

   
    <Card className="my-3 p-3 rounded">


    <Link to={`/project/${proj._id}`}>
      <Card.Img src={proj.image} />
    </Link>

    <Card.Body>
      <Link to={`/project/${proj._id}`}>
        <Card.Title as="div">
          <strong>{proj.name}</strong>
        </Card.Title>
      </Link>

      <Card.Text as="div">
          <div className="my-3">
              {frt.slice(0,10) +'...' } | {bcd.slice(0,10) +'...'} | {dbs.slice(0,10) +'...'}
          </div>
      </Card.Text>  
      <Link to={`/project/${proj._id}`}>
      <Button className="btn btn-sm btn-success m-2"> Know More <i className="fa-solid fa-circle-info"></i></Button>

    </Link>

    </Card.Body>
  </Card>

  )
}

export default Projects