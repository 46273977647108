export const USER_CONTACT_REQUEST= 'USER_CONTACT_REQUEST'
export const USER_CONTACT_SUCCESS ='USER_CONTACT_SUCCESS'
export const USER_CONTACT_FAIL = 'USER_CONTACT_FAIL'


export const PROFILE_LIST_REQUEST= 'PROFILE_LIST_REQUEST'
export const PROFILE_LIST_SUCCESS ='PROFILE_LIST_SUCCESS'
export const PROFILE_LIST_FAIL = 'PROFILE_LIST_FAIL'


