import axios from 'axios'
import {
    TECHNOLOGY_LIST_REQUEST,TECHNOLOGY_LIST_SUCCESS,
TECHNOLOGY_LIST_FAIL,
    TECHNOLOGY_DETAILS_REQUEST,TECHNOLOGY_DETAILS_SUCCESS,
TECHNOLOGY_DETAILS_FAIL


} from '../constants/techConstants'


export const listTechnologys =() => async (dispatch)=>{

    try{
        dispatch({type:TECHNOLOGY_LIST_REQUEST})
        const {data} = await axios.get('/api/technolgies/')

        dispatch({
            type:TECHNOLOGY_LIST_SUCCESS,
            payload:data
        })

    }
    catch(error){
        dispatch({
            type:TECHNOLOGY_LIST_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            :error.message,
        })

    }
}

export const listTechnologyDetails =(id) => async (dispatch)=>{

    try{
        dispatch({type:TECHNOLOGY_DETAILS_REQUEST})
        const {data} = await axios.get(`/api/technolgies/${id}`)

        dispatch({
            type:TECHNOLOGY_DETAILS_SUCCESS,
            payload:data
        })

    }
    catch(error){
        dispatch({
            type:TECHNOLOGY_DETAILS_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            :error.message,
        })

    }
}