import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  Card,
  ListGroup,
} from "react-bootstrap";
import Loader from "./Loader";
import Message from "./Message";
import { useDispatch, useSelector } from "react-redux";
import Rating from "./Rating";
import {
  listProjectsDetails,
  createProjectReview,
} from "../actions/projectsAction";
import { PROJECT_CREATE_REVIEW_RESET } from "../constants/projectsConstants";

function ProjectsData() {
  const { id } = useParams();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const projectDetails = useSelector(state => state.projectDetails);
  const { loading, error, proj } = projectDetails;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;
  const projectReviewCreate = useSelector(state => state.projectReviewCreate);
  const {
    loading: loadingProjectReview,
    error: errorProjectReview,
    success: successProjectReview,
  } = projectReviewCreate;




  useEffect(() => {
    if(successProjectReview) {
      setRating(0)
      setComment('')
      dispatch({ type: PROJECT_CREATE_REVIEW_RESET })
      
  }
    dispatch(listProjectsDetails(id));

  }, [dispatch, id,successProjectReview]);


  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProjectReview(id, {
        rating,
        comment,
      })
    );
  };

  return (
    <div className="container">
      <Link to="/projects" className="btn btn-dark my-3">
      <i className="fa-solid fa-backward"></i> Go Back 
      </Link>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error} </Message>
      ) : (
        <Row>
          <Col md={3}>
            <Image src={proj.image} alt={proj.name} fluid />
            <hr />


            <h4>Total Reviews  {proj.reviews.length}</h4>

             
            {proj.numReviews === 0 && (
              <Message variant="info">No Reviews</Message>
            )}
   <ListGroup variant="flush">
              {proj.reviews.map((review) => (
                <ListGroup.Item key={review._id}>
                  <strong>{review.name}</strong>
                  <Rating value={review.rating} color="#f8e825" />
                  <p>{review.createdAt.substring(0, 10)}</p>
                  <p>{review.comment}</p>
                </ListGroup.Item>
              ))}

              <ListGroup.Item>
                <h4>Write a review</h4>

                {loadingProjectReview && <Loader />}
                {successProjectReview && (
                  <Message variant="success">Review Submitted</Message>
                )}
                {errorProjectReview && (
                  <Message variant="danger">{errorProjectReview}</Message>
                )}

                {userInfo ? (
                  <Form onSubmit={submitHandler}>
                    <Form.Group controlId="rating">
                      <Form.Label>Rating</Form.Label>
                      <Form.Control
                        as="select"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                      >
                        <option value="">Select...</option>
                        <option value="1">1 - Poor</option>
                        <option value="2">2 - Fair</option>
                        <option value="3">3 - Good</option>
                        <option value="4">4 - Very Good</option>
                        <option value="5">5 - Excellent</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="comment">
                      <Form.Label>Review</Form.Label>
                      <Form.Control
                        as="textarea"
                        row="5"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Button
                      disabled={loadingProjectReview}
                      type="submit"
                      variant="primary"
                      className="mt-2"
                    >
                      Submit
                    </Button>
                  </Form>
                ) : (
                  <Message variant="info">
                    Please <Link to="/signin">login</Link> to write a review
                  </Message>
                )}
              </ListGroup.Item>
            </ListGroup>  
          
          </Col>

          <Col md={6}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h3>{proj.name}</h3>
              </ListGroup.Item>
              <ListGroup.Item>
                <Rating value={proj.rating} color={"#f8e825"} />
              </ListGroup.Item>
              <ListGroup.Item>Frontend: {proj.frontend}</ListGroup.Item>

              <ListGroup.Item>Backend: {proj.backend}</ListGroup.Item>

              <ListGroup.Item>
              <Row>
                    <h5 className="bg-dark text-light m-0 p-2"><u>About Project</u>:</h5>
                    <Col as="p" dangerouslySetInnerHTML={{__html: proj.project_description}}>
                       
                    </Col>
                </Row>
                
                
              </ListGroup.Item>
            </ListGroup> 
          </Col>
          <Col md={3}>
            <Card>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col>Installation </Col>
                    <Col>
                      <a href={`${proj.installationLink}`}>Install</a>
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col>Code :</Col>
                    {userInfo ? (
                    <Col><a className="text-light bg-success p-2" href={`${proj.codeLink}`}>Download <i className="fa-solid fa-download"></i></a></Col>) : (
                      <Col> <Link to="/signin" className="text-info">
                    Signin to Download Code <i className="fa-solid fa-download"></i>
                    </Link>  </Col>) }
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Report :</Col>
                    {userInfo ? (
                    <Col><a className="text-light bg-success p-2" href={`${proj.reportLink}`}>Download <i className="fa-solid fa-download"></i></a></Col>) : (
                      <Col> <Link to="/signin" className="text-info">
                    Signin to Download Report <i className="fa-solid fa-download"></i>
                    </Link>  </Col>) }
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Need Help ? </Col>
                    <Col className="text-primary">
                    <Link to="/contact" className="btn btn-outline-info">
        Contact 
      </Link>
                      
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Emergency Help:</Col>
                    <Col className="text-primary">
                  
                
      <i  id="sicon"  className="fa-brands fa-instagram"></i><a target="_blank" href="https://www.instagram.com/anees_rehman_khan_/"> click</a>
   
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default ProjectsData;
