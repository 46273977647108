import {createStore,combineReducers,applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import { userLoginReducers, userRegisterReducers,userDetailsReducers,userUpdateProfileReducers } from './reducers/userReducers';
import { technologysListReducers,technologyDetailsReducers } from './reducers/techReducers';
import { servicesDetailsReducers,servicesListReducers } from './reducers/serviceReducers';
import { projectReviewCreateReducer, projectsDetailsReducers,projectsListReducers } from './reducers/projectReducers';
import { profileListReducers, userContactReducers } from './reducers/contactReducers';
import { workListReducers } from './reducers/workReducers';
import { youtubeDetailsReducers, youtubeListReducers } from './reducers/youtubeReducers';
import { blogsDetailsReducers, blogsListReducers } from './reducers/blogReducers';
import {workshopDetailsReducers, workshopsListReducers } from './reducers/workshopReducers';
import { workshopEnrollDetailsReducers, workshopEnrollReducers } from './reducers/enrollReducers';






const reducer =combineReducers({
    userLogin:userLoginReducers,
    userRegister:userRegisterReducers,
    userDetails:userDetailsReducers,
    userUpdateProfile:userUpdateProfileReducers,
    technologysList:technologysListReducers,
    technologyDetails:technologyDetailsReducers,
    servicesList:servicesListReducers,
    serviceDetails:servicesDetailsReducers,
    projectsList:projectsListReducers,
    projectDetails:projectsDetailsReducers,
    projectReviewCreate:projectReviewCreateReducer,
    contactRegister:userContactReducers,
    profileList:profileListReducers,
    workList:workListReducers,
    youtubeList:youtubeListReducers,
    youtubeDetails:youtubeDetailsReducers,
    blogsList:blogsListReducers,
    blogDetails:blogsDetailsReducers,
    workshopsList:workshopsListReducers,
    workshopDetails:workshopDetailsReducers,
    workshopEnroll:workshopEnrollReducers,
    workshopregisterDetails:workshopEnrollDetailsReducers



})

const userInfoFromStorage = localStorage.getItem('userInfo')?
JSON.parse(localStorage.getItem('userInfo')): null


const initailState = {
    userLogin:{userInfo:userInfoFromStorage},
    
}

const middleware=[thunk]
const store = createStore(reducer,initailState,composeWithDevTools(applyMiddleware(...middleware)))

export default store;