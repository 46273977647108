import React,{useEffect} from "react";
import { Link,useParams } from "react-router-dom";
import { Row, Col, Image, ListGroup} from "react-bootstrap";
import Loader from '../Loader';
import Message from '../Message';
import { useDispatch, useSelector } from "react-redux";
import { listblogDetails } from "../../actions/blogsAction";

function BlogsScreen() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const blogDetails = useSelector((state) => state.blogDetails);
  const { loading, error, blog } = blogDetails;
  useEffect(() => {
    dispatch(listblogDetails(slug));
  }, [dispatch,slug]);
  

  return  (

    <div className="container">
      <Link to="/blog" className="btn btn-dark my-3">
      <i className="fa-solid fa-backward"></i> Go Back
      </Link>

      {loading ? (
        <Loader />
        
      ) : error ? (
        <Message variant='danger'>{error} </Message>
      ) : (
        <Row>
          <Col md={3}>
            <Image src={blog.img} alt={"not found image"} fluid />
          </Col>

          <Col md={9}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h3>{blog.title}</h3>
              </ListGroup.Item>
              
              <ListGroup.Item>Posted by: {blog.author}</ListGroup.Item>
              <ListGroup.Item>
                {blog.timeStamp}
           </ListGroup.Item>
            
              <ListGroup.Item as="p" dangerouslySetInnerHTML={{__html: blog.content}}> 
         
         </ListGroup.Item>

           
            </ListGroup>
          </Col>
     
        </Row>
           
      )}

   
    </div>
  );
}

export default BlogsScreen