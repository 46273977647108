import axios from 'axios'
import {
    WORKSHOP_LIST_REQUEST,WORKSHOP_LIST_SUCCESS,
WORKSHOP_LIST_FAIL,
    WORKSHOP_DETAILS_REQUEST,WORKSHOP_DETAILS_SUCCESS,
WORKSHOP_DETAILS_FAIL


} from '../constants/workshopConstants'


export const listWorkshops =() => async (dispatch)=>{

    try{
        dispatch({type:WORKSHOP_LIST_REQUEST})
        const {data} = await axios.get('/api/workshop/')

        dispatch({
            type:WORKSHOP_LIST_SUCCESS,
            payload:data
        })

    }
    catch(error){
        dispatch({
            type:WORKSHOP_LIST_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            :error.message,
        })

    }
}

export const listWorkshopDetails =(id) => async (dispatch)=>{

    try{

        dispatch({type:WORKSHOP_DETAILS_REQUEST})
        const {data} = await axios.get(`/api/workshop/${id}`)

        dispatch({
            type:WORKSHOP_DETAILS_SUCCESS,
            payload:data
        })

    }
    catch(error){
        dispatch({
            type:WORKSHOP_DETAILS_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            :error.message,
        })

    }
}