import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
    Card,
  Row,
  Col,
  Image,
  
  ListGroup,
} from "react-bootstrap";
import {Button } from "react-bootstrap";
import Loader from "./Loader";
import Message from "./Message";
import { useDispatch, useSelector } from "react-redux";
import {
  listWorkshopDetails,
} from "../actions/workshopActions";
import Rating from "./Rating";

function WorkshopsData() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const workshopDetails = useSelector((state) => state.workshopDetails);
    const { loading, error, workshop } = workshopDetails;
    useEffect(() => {
      dispatch(listWorkshopDetails(id));
    }, [dispatch,id]);


  return (

  <div className="container">
      <Link to="/workshop" className="btn btn-dark my-3">
      <i className="fa-solid fa-backward"></i> Go Back
      </Link>

      {loading ? (
        <Loader />
        
      ) : error ? (
        <Message variant='danger'>{error} </Message>
      ) : (
        <Row>
          <Col md={3}>
          <Card.Img src={workshop.image} />
          <Link to={`/enroll/`}>
                <Button className="btn btn-sm btn-success m-2">
                  {" "}
                  Register Now <i className="fa-solid fa-circle-info"></i>
                </Button>
              </Link>
          </Col>

          <Col md={6}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h3><span><i className={workshop.icon}></i> </span> {workshop.workshop_name}</h3>
              </ListGroup.Item>
              <ListGroup.Item>
              <Rating value={5} color={"#f8e825"} />
              </ListGroup.Item>
              <ListGroup.Item><h3>Pay only if you are satisfied after demo live session</h3> </ListGroup.Item>
              <ListGroup.Item><h4>Price: {workshop.price} Rs</h4></ListGroup.Item>

            
              <ListGroup.Item as="p" dangerouslySetInnerHTML={{__html: workshop.description}}> 
            
         
         </ListGroup.Item>

           
            </ListGroup>
          </Col>
          <Col md={3}>
            <Card>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col>Quality</Col>
                    <Col>
                    <Rating value={5} color={"#f8e825"} />
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col>Starts On:</Col>
                    <Col>
                       {workshop.createdAt} Years
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item> 
                <Row>
                    <h5 className="bg-dark text-light m-0 p-2"><u>Content</u>:</h5>
                    <Col as="p" dangerouslySetInnerHTML={{__html: workshop.concepts}}>
                       
                    </Col>
                </Row>
                
         
         </ListGroup.Item>
              
         

                
              


              </ListGroup>
            </Card>
          </Col>
        </Row>
           
      )}

   
    </div>
  );
}

export default WorkshopsData;
