import React, {useEffect } from 'react'
import Loader from '../Loader';
import Message from '../Message';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { listYoutube } from '../../actions/youtubeAction';
import Youtube from '../Youtube';


function PlaylistScreen() {

    const dispatch = useDispatch();
    const youtubeList = useSelector((state) => state.youtubeList);
    const { error, loading, youtube } = youtubeList;
  
  
    useEffect(() => {
      dispatch(listYoutube())
  
    }, [dispatch]);
  return (
    <>
    <h1 className='text-center'>Playlist</h1>

    {loading ? (
           <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Row className='m-3'>
              {youtube.map((youtubeplaylist) => (
                <Col key={youtubeplaylist._id} sm={12} md={6} lg={4} xl={3}>
                
                  <Youtube youtubeplaylist={youtubeplaylist} />
                </Col>
              ))}
            </Row>
          )}
</>    
  )
}

export default PlaylistScreen