import React from "react";
import { Card } from "react-bootstrap";
import {Link} from "react-router-dom"

function Services({service}) {
  return (
    <Card  className="my-3 p-3 rounded">
    <Link to={`/service/${service._id}`}>
      <Card.Img src={service.image} style={{ width: 100 }} fuild/>
    </Link>

    <Card.Body>
      <Link to={`/service/${service._id}`}  style={{ textDecoration: 'none' }}>
        <Card.Title >
          <h4>{service.service_name}</h4>
        </Card.Title>
      </Link>

      <Card.Text as="div">
          <div className="my-3">
             Experience {service.experience} Years
          </div>
      </Card.Text>

      <Card.Text as="div">
          <div className="my-3 text-black">
             No. Of Clients {service.clients} 
          </div>
      </Card.Text>
      

      <Link  to={`/service/${service._id}`} className="btn btn-outline-dark">
                    Know More
                </Link>
      
    
  
     

      
    </Card.Body>
  </Card>
  )
}

export default Services