import React,{useState,useEffect} from 'react'
import { Row, Col, Button,Form ,Card,InputGroup } from "react-bootstrap";
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate  } from 'react-router-dom';
import Message from '../Message';
import { Alert } from 'react-bootstrap'
import Loader from '../Loader';
import { enroll } from '../../actions/enrollAction';
import { getUserDetails,updateUserProfile } from "../../actions/userAction";
import { USER_UPDATE_PROFILE_RESET } from '../../constants/userConstants';
function Enroll() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [course, setCourse] = useState('Python Basic to Advance');
    const [usn, setUsn] = useState('');
    const [collegename, setCollegename] = useState('');
    const [state, setState] = useState('');
    const [gender, setGender] = useState('Select Gender');
    const [phonenumber, setPhonenumber] = useState('');
    const [message, setMessage] = useState("");

    
   const userDetails = useSelector(state=>state.userDetails)
   const {user}=userDetails

   const userLogin = useSelector(state=>state.userLogin)
   const {userInfo}=userLogin

   const userUpdateProfile = useSelector(state=>state.userUpdateProfile)
   const { success }=userUpdateProfile
 
   useEffect(()=>{
     console.log(user.id)
     if(!userInfo){
         navigate('/register')
     }
     else{
        if(!user || !user.name || success){
          dispatch({type:USER_UPDATE_PROFILE_RESET})
          dispatch(getUserDetails('profile'))
        }
     else
       {
         setName(user.name)
         setEmail(user.email)
 
       }
     }
 
    }
 ,[dispatch,user,userInfo])
 



  const submitHandler = (e) => {
    e.preventDefault()
    
    if (phonenumber.length!=10) {
      console.log("anees");
      setMessage("Please provide 10 digit mobile number");
      navigate("/enroll");
    } 
      else{
        dispatch(enroll(name, email, course,usn,collegename,state,gender,phonenumber))
        setName(user.name);
        setEmail(user.email);
        setCourse('Python Basic to Advance');
        setUsn('');
        setCollegename('');
        setState('');
        setGender('');
        setPhonenumber('');
        setMessage('');
        navigate('/updateprofile')
      }
    }

    const workshopEnroll = useSelector(state=>state.workshopEnroll)
    const {error,loading}=workshopEnroll


  return (
    <>
    <br />
    <Container className='mt-2' >
   <Row>
    
      <Col md={4}>
      <a href="/#/updateprofile" className="btn btn-sm btn-success mb-3" type="button"><i className="fa-solid fa-eye fa-beat-fade"></i> View Profile</a>
      <br />
      </Col>
      <Col md={4}>
      <Card>
      <Card.Header  as="h1" className='text-center bg-dark text-light'>Enroll Now <i className="fa-solid fa-right-to-bracket fa-fade"></i></Card.Header> 
      {message && <Alert variant={"warning"}>
            {message}
        </Alert>}
      <Card.Body>
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}
  <Form  onSubmit={submitHandler}>

  
  <Form.Group controlId='name'  className="mb-3">
    
    <Form.Control type='text' placeholder='Enter Name' value={name} onChange={(e) => setName(e.target.value)} required></Form.Control>
  </Form.Group>
  <Form.Group controlId='email'  className="mb-3">

    <Form.Control type='email' value={email} placeholder='Enter Email' onChange={(e) => setEmail(e.target.value)} required></Form.Control>
  </Form.Group>

  <Form.Group controlId='course'  className="mb-3">

    <Form.Control type='text' value={course} onChange={(e) => setCourse(e.target.value)} required></Form.Control>
  </Form.Group>




  <Form.Group controlId='usn'  className="mb-3">

    <Form.Control type='text' value={usn} placeholder='Enter Roll Number / NA for passed out' onChange={(e) => setUsn(e.target.value)} required></Form.Control>
  </Form.Group>

  <Form.Group controlId='collegename'  className="mb-3">

    <Form.Control type='text' value={collegename} placeholder='Enter Your College Name' onChange={(e) => setCollegename(e.target.value)} required></Form.Control>
  </Form.Group>

  <Form.Group controlId='state'  className="mb-3">

    <Form.Control type='text' value={state} placeholder='Enter Your State' onChange={(e) => setState(e.target.value)} required></Form.Control>
  </Form.Group>
  

  <Form.Group controlId='gender'  className="mb-3">

    <Form.Select  value={gender}  onChange={(e) => setGender(e.target.value)} required>
     
      <option value="gender">Select Gender</option>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
    </Form.Select>
  </Form.Group>


  <Form.Group controlId='phonenumber'  className="mb-3">

    <Form.Control type='number' value={phonenumber} placeholder='Enter Your Phone Number' onChange={(e) => setPhonenumber(e.target.value)} required></Form.Control>
  </Form.Group>
  
 
  <div className="d-grid gap-2">
    <Button className="btn btn-outline-success" variant='light' type="submit">Enroll</Button>
  
  </div>
  
  </Form>
  
 
  </Card.Body>
  
  </Card>
  
  
  
      </Col>
      <Col md={4}></Col>
   </Row>
    </Container>
    </>
  )
}

export default Enroll