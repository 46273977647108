import React,{useEffect} from "react";
import { Link,useParams } from "react-router-dom";
import { Row, Col, Image,Card ,ListGroup} from "react-bootstrap";
import Loader from '../Loader';
import Message from '../Message';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { listTechnologyDetails } from "../../actions/techAction";
import Rating from "../Rating";

function TechScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const technologyDetails = useSelector((state) => state.technologyDetails);
  const { loading, error, tech } = technologyDetails;
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(listTechnologyDetails(id));
  }, [dispatch,id]);
  

  return  (
    <div className="container">
      <Link to="/" className="btn btn-dark my-3">
      <i className="fa-solid fa-backward"></i> Go Back
      </Link>

      {loading ? (
        <Loader />
        
      ) : error ? (
        <Message variant='danger'>{error} </Message>
      ) : (
        <Row>
          <Col md={3}>
            <Image src={tech.image} alt={tech.technology_name} fluid />
          </Col>

          <Col md={6}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h3>{tech.technology_name}</h3>
              </ListGroup.Item>
              <ListGroup.Item>
              <Rating value={tech.skillrating} color={"#f8e825"} />
              </ListGroup.Item>
              <ListGroup.Item>Technology: {tech.category}</ListGroup.Item>

            
              <ListGroup.Item as="p" dangerouslySetInnerHTML={{__html: tech.description}}> 
         
         </ListGroup.Item>

           
            </ListGroup>
          </Col>
          <Col md={3}>
            <Card>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col>Skill:</Col>
                    <Col>
                    <Rating value={tech.skillrating} color={"#f8e825"} />
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col>Experience:</Col>
                    <Col>
                       {tech.experience} Years
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item> 
                <Row>
                    <h5 className="bg-dark text-light m-0 p-2"><u>Concepts Knows</u>:</h5>
                    <Col as="p" dangerouslySetInnerHTML={{__html: tech.concepts}}>
                       
                    </Col>
                </Row>
                
         
         </ListGroup.Item>
              
         

                
              


              </ListGroup>
            </Card>
          </Col>
        </Row>
           
      )}

   
    </div>
  );
}

export default TechScreen