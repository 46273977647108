import React, {useEffect } from 'react'
import Loader from '../Loader';
import Message from '../Message';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { listBlogs } from '../../actions/blogsAction';
import Blogs from '../Blogs';

function Blog() {
  const dispatch = useDispatch();
  const blogsList = useSelector((state) => state.blogsList);
  const { error, loading, blogs } = blogsList;

  useEffect(() => {
    dispatch(listBlogs())

  }, [dispatch]);
  return (
<>

{loading ? (
           <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Row className='m-3'>
              <h1>Welcome to My Blog </h1>
              {blogs.map((blg) => (
                <Col key={blg.id} sm={12} md={8} lg={8} xl={8}>
                
                  <Blogs blg={blg} />
                </Col>
              ))}
            </Row>
          )}

</>
  )
}

export default Blog