import React, {useEffect } from 'react'
import Loader from '../Loader';
import Message from '../Message';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { listYoutubeDetails } from '../../actions/youtubeAction';
import YoutubePlaylistVideos from '../YoutubePlaylistVideos';



function YoutubePlaylistScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const youtubeDetails = useSelector((state) => state.youtubeDetails);
  const { error, loading, youtubes } = youtubeDetails;


  useEffect(() => {
    dispatch(listYoutubeDetails(id))

  }, [dispatch]);


  return (
    <>
    <h1 className='text-center'>Playlist </h1>
 <div className='m-3'> <Link to="/youtube" className="btn btn-danger">
      <i className="fa-solid fa-backward"></i> Go Back 
      </Link></div>
 

    {loading ? (
           <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Row className='m-3'>
                
              {youtubes.map((youtubedata) => (
                <Col key={youtubedata._id} sm={12} md={6} lg={6} xl={3}>
                
                  <YoutubePlaylistVideos youtubedata={youtubedata} />
                </Col>
              ))}
            </Row>
          )}
</>    
  )
}

export default YoutubePlaylistScreen