import React from 'react'
import { Container,Row,Col} from 'react-bootstrap'
import {Link} from "react-router-dom"
function Footer() {
    return (
   
            <footer>

                <Container>
                    <Row>
                        <Col className="text-center py-3">Copyrights @copy; ArkProCoder</Col> 
                        <Col className="text-center py-3">
                          
                            
                            <Link className="text-dark text-center" target="_blank" to={`/privacy` }

        >Privacy Policy <i className="fa-solid fa-eye"></i></Link>
                          
                            
                        </Col> 
                      
                    </Row>
                </Container>
            </footer>
     
    )
}

export default Footer