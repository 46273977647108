import { WORKSHOP_ENROLL_REQUEST,WORKSHOP_ENROLL_SUCCESS,WORKSHOP_ENROLL_FAIL,
    WORKSHOP_ENROLL_REQUEST_DETAILS,WORKSHOP_ENROLL_SUCCESS_DETAILS,WORKSHOP_ENROLL_FAIL_DETAILS

} from '../constants/enrollConstants'


export const workshopEnrollReducers = (state={  },action) =>{

    switch(action.type){
        case WORKSHOP_ENROLL_REQUEST:
            return {loading:true}
        case WORKSHOP_ENROLL_SUCCESS:
            return {loading:false,userInfo:action.payload}
        case WORKSHOP_ENROLL_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}
export const workshopEnrollDetailsReducers = (state={ },action) =>{

    switch(action.type){
        case WORKSHOP_ENROLL_REQUEST_DETAILS:
            return {loading:true}
        case WORKSHOP_ENROLL_SUCCESS_DETAILS:
            return {loading:false,workshopInfo:action.payload}
        case WORKSHOP_ENROLL_FAIL_DETAILS:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}